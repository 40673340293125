.day {
  --background: #fefefe;
  --text: #2c2d30;
  --link: #1264a3;
  --link-hover: #0b4c8c;
  --shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  --ocean: rgba(47, 156, 208, 0.12);
  --empty-country: #fff;
  --country: #309cd0;
  --hover-country: #226a8c;
  --selected-country: #ff5722;
}

.night {
  --background: #132934;
  --text: #ffffe0;
  --link: #e88030;
  --link-hover: #ff9c52;
  --shadow: 0 0 10px rgba(255, 255, 255, 0.2);

  --ocean: rgba(47, 156, 208, 0.12);
  --empty-country: #ffffff17;
  --country: #127db0;
  --hover-country: #169ad8;
  --selected-country: #c25736;
}

:root {
  --pencil-frame: 90vmin;
  --pencil-aside: 10vmin;
  --pencil-full: calc(var(--pencil-frame) + var(--pencil-aside));
}

body {
  margin: 0;
  text-align: center;
  width: 100%;
}

body,
button,
select {
  background-color: var(--background);
  color: var(--text);
  font-size: 1.6rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
select {
  max-width: 100%;
  text-align: center;
}

a {
  color: var(--link);
}
a:hover {
  color: var(--link-hover);
}
