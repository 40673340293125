.root {
  text-align: center;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
}

.clear {
  margin: 0 0.4rem;
  font-size: 1rem;
  text-decoration: none;
  border-bottom: 2px dotted;
  font-weight: 300;
}
