.root {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30vh, 1fr));
  grid-auto-rows: minmax(30vh, auto);
  grid-auto-flow: dense;
}

.size2 {
  grid-column-end: span 2;
  grid-row-end: span 2;
}

.size3 {
  grid-column-end: span 3;
  grid-row-end: span 3;
}

@media screen and (max-width: 800px) {
  .Grid {
    grid-template-columns: auto;
    grid-auto-rows: auto;
  }
}

.item {
  cursor: zoom-in;
  transition: transform 0.15s ease-in;
  display: flex;
  align-items: center;
}

.item:focus {
  z-index: 2;
}

.item:hover {
  box-shadow: var(--shadow);
  transform: scale(1.02);
  z-index: 3;
  background-color: var(--background);
}

.item:active {
  transform: scale(0.98);
}

.item img {
  width: 100%;
}
