.info {
  height: 100%;
  background-color: var(--background);

  display: flex;
}
.info h1 {
  font-size: larger;
  margin: 0.5rem 0;
}
.info h2 {
  font-size: smaller;
  margin: 0;
}

@media screen and (max-width: 600px), (max-height: 600px) {
  .info h1,
  .info p,
  .info h2 {
    margin: 0;
    font-size: medium;
  }
  .tag,
  .main {
    font-size: small;
    line-height: 1.2;
  }
}

.scroller {
  flex: 1;
  height: 100%;
  scroll-snap-type: both mandatory;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.frame {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  scroll-snap-align: center;
  z-index: 1;
}

.photo {
  width: 100%;
  height: 100%;
  cursor: zoom-out;
}

.content {
  background-color: var(--bg-color);
  padding: 1rem;
  color: var(--text);
  word-break: break-word;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;
}
.content article {
  line-height: 1.4;
}

.main {
  flex: 1;
  overflow-y: auto;
  position: relative;
  text-align: left;
}

.main::before,
.main::after {
  content: '';
  height: 1rem;
  display: block;
  position: sticky;
  left: 0;
  right: 0;
}
.main::before {
  top: 0;
  background-image: linear-gradient(var(--bg-color), var(--bg-color-transparent));
}
.main::after {
  bottom: 0;
  background-image: linear-gradient(var(--bg-color-transparent), var(--bg-color));
}

.main p:first-of-type {
  margin-top: 0;
}

.tags {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: auto;
}
.tag {
  margin-left: 0.5rem;
  text-transform: lowercase;
  display: inline-block;
}

.thumbs {
  flex: 0 0 var(--pencil-aside);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

button.thumb {
  font-size: 1rem;
  word-break: break-word;
  overflow: hidden;
  border: none;
}

.thumb {
  cursor: pointer;
  flex: 0 1 var(--pencil-aside);
  height: var(--pencil-aside);
  width: var(--pencil-aside);
  display: block;
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;

  /* opacity: 0.3; */
  transition: all 0.1s ease-in;

  background-size: cover;
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

@media (orientation: portrait) {
  .info {
    flex-direction: column-reverse;
  }
  .thumbs {
    flex-direction: row;
    flex-basis: var(--pencil-aside);
  }

  .thumb {
    flex: 0 1 var(--pencil-aside);
    width: var(--pencil-aside);
    height: var(--pencil-aside);
  }
}

.highlight,
.thumb:active,
.thumb:hover {
  opacity: 1;
  background-size: 150%;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
}
