.root {
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 3rem;
}

.root:hover {
  text-decoration: underline;
}
