.root {
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.root span {
  margin: 0 1rem;
  display: inline-block;
  text-align: center;
}
