.map {
  background: var(--ocean);
  box-shadow: inset 0 0 50px rgba(0, 64, 105, 0.1);
}

.block {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.flags {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 1;
  pointer-events: none;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .block {
    height: 220px;
  }
}

.country {
  fill: var(--empty-country);
}

.pencil {
  fill: var(--country);
  cursor: pointer;
}
.pencil:hover {
  fill: var(--hover-country);
  transition: fill 0.2s ease;
}

.selected,
.selected:hover {
  fill: var(--selected-country);
}
