.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.root button {
  flex: 1 0 100%;
  width: 100%;
  white-space: nowrap;
}
